import { graphql } from "gatsby";
import React from "react";
import { Box, Flex, Grid, Text } from "theme-ui";
import Card from "../components/card";
import Layout from "../components/layout";
import AppButton from "../components/layout/app-button";
import ColumnContainer from "../components/layout/column-container";
import Scroller from "../components/layout/scroller";
import ThemeableHeader from "../components/layout/themeable-header";
import NewsletterSignup from "../components/page-blocks/newsletter-signup";
import TwitterCallToAction from "../components/page-blocks/twitter-cta";
import Seo from "../components/seo";
import { getFlatFields } from "../lib/text-helpers";

const CategoryIntro = ({ name, description }) => {
  return (
    <Box sx={{ pt: [4, 5], mb: [3, 4, 5] }}>
      <ColumnContainer>
        <Grid columns={[1, 2]} gap={(2, 3, 4)}>
          <Text sx={{ fontSize: [5, 6] }} as="h2" variant="title">
            {name}
          </Text>
          <Text sx={{ maxWidth: "600px" }} as="h3" variant="subtitle">
            {description}
          </Text>
        </Grid>
      </ColumnContainer>
    </Box>
  );
};

const DataStories = ({
  data: {
    categoryResult: { categories },
    storyResult: { dataStories },
  },
}) => {
  const dataStoriesByCategory = (categorySlug) => {
    return dataStories.filter((d) => {
      return d?.dataCategories?.nodes[0]?.slug === categorySlug;
    });
  };
  return (
    <Layout>
      <Seo
        title="Data Stories"
        description="So much data is collected in the world of education. Here we collate and present some of the pieces we find most interesting. If there is data you think we should include, we'd love to hear from you."
      />
      <ThemeableHeader
        color="darkGreen"
        title={"Data Stories"}
        active="/data-stories-2"
        pageIntroduction="So much data is collected in the world of education. Here we collate and present some of the pieces we find most interesting. If there is data you think we should include, we'd love to hear from you."
      />
      <Grid as="section" columns={1} gap={[5, 6]} sx={{ my: [5, 6] }}>
        {categories.reverse().map((category, index) => (
          <ColumnContainer key={`category-${index}`}>
            <Box
              sx={{
                bg: "offWhite",
                overflow: "hidden",
                borderRadius: 30,
                zIndex: 0,
              }}
            >
              <CategoryIntro {...category} />
              <Scroller backgroundColorValues="244,244,244">
                {dataStoriesByCategory(category.slug).map((ds) => {
                  return (
                    <Card
                      {...getFlatFields(ds)}
                      background="white"
                      key={ds.slug}
                    />
                  );
                })}
              </Scroller>
              <Flex
                sx={{
                  pb: [3, 4],
                  px: [3, 4],
                  width: "100%",
                  justifyContent: "flex-end",
                }}
              >
                <AppButton
                  to={`/data-stories/${category.slug}`}
                  icon="arrowRight"
                  color="darkGreen"
                  colors={{
                    default: {
                      background: "darkGreen",
                      text: "white",
                    },
                  }}
                >
                  View {category.name}
                </AppButton>
              </Flex>
            </Box>
          </ColumnContainer>
        ))}
        <TwitterCallToAction />
        <NewsletterSignup />
      </Grid>
    </Layout>
  );
};

export const query = graphql`
  {
    categoryResult: allWpDataCategory {
      categories: nodes {
        slug
        name
        description
      }
    }
    storyResult: allWpDataStory(
      filter: { additionalInfo: { hide: { ne: true } } }
      sort: { fields: dateGmt, order: DESC }
    ) {
      dataStories: nodes {
        postType: nodeType
        slug
        additionalInfo {
          standfirst
        }
        dataCategories {
          nodes {
            slug
            name
          }
        }
        title
        fields: dataStoryCustomFields {
          data: dataFile {
            url: mediaItemUrl
            csvJSON
            geoJSON
          }
          story
          dataTemplate
          sourceLabel
          sourceUrl
        }
      }
    }
  }
`;

export default DataStories;
